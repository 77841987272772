







import infoFilterList from "@/components/infoFilters/infoFilterList.vue";
import ListTable from "@/components/listTable/listTable.vue";
export default {
  data() {
    return {};
  },
  methods: {},
  components: {
    infoFilterList,
    ListTable
  }
};
